"use strict";

if (isPreview) {

  // open raw xml mode in new tab on ctrl+alt+r
  (function open_raw_mode(){
    var url = (window.location + '').split('#')[0];
    $(window).keypress(function(e){
      var code = e.keyCode || e.which;
      if(code == 174 && e.ctrlKey && e.altKey) {
        var params = url.split('?')[1] ? '?' + url.split('?')[1] : '';
        var newUrl = url.split('?')[0] + '/raw' + params;
        window.open(newUrl,'_blank');
      }
    });
  })();

}
else {

  // google analytics
  //if (gaCode) {
  //  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','//www.google-analytics.com/analytics.js','ga');
  //  ga('create',gaCode,'auto');
  //  ga('send','pageview');
  //}

}

// check if device is mobile
function mobile(){
  return $(window).outerWidth() < 840;
}

// check if device is tablet
function tablet(){
  return $(window).outerWidth() < 1160 && !mobile();
}

if (mobile()) {

  // main menu
  var ulVis    = $('.nav-main__visible-list');
  var ulInvis  = $('.nav-main__list');
  var liWidths = $('li',ulVis).map(function(){ return $(this).outerWidth() }).get(); // put menu item widths in array

  $(window).resize(function(){
    var totalWidth = 40;                     // start width for more-button
    var bodyWidth  = $('body').outerWidth(); // use body-width because window-width may not be correct

    $('li', ulVis.add(ulInvis)).each(function(i){
      totalWidth += liWidths[i];
      bodyWidth < totalWidth ? ulInvis.append(this) : ulVis.append(this); // put menu item in appropriate list
    });

    $('span.nav-main__list-wrapper').css('display', bodyWidth < totalWidth ? 'inline-block' : 'none'); // toggle more-button

  }).resize();

  $('#nav-main').css('visibility','visible'); // make only visible after first calculation to prevent flickering

  // footer
  $('.footer__sitemap-item-title').click(function(){
    $(this).toggleClass('is-open');
    $(this).next().toggle();
  });

} else {

/*  $(window).resize(function(){
    $('main').css({height:$('body').height()});
  }).resize();*/

  $(window).scroll(function(){
    var scrolltop = $(this).scrollTop();

    //if (scrolltop > 5) $('#breadcrumbs').hide();
    //else $('#breadcrumbs').fadeIn(200);

    if (scrolltop < 40) $('body').removeClass('fixnav');
    else $('body').addClass('fixnav');
  }).scroll();

}


$('#sidemenu').on('click','a[href^="#"]',function(e){
  // Scroll the window, stop any previous animation, stop on user manual scroll
  $(window).stop(true).scrollTo(decodeURIComponent(this.hash), {axis: 'y', duration:1000, interrupt:true});
});

$('body').on('click',function(e){
  e.stopPropagation();
  $('#nav-main .rw-nav__dropdown-container').addClass('hidden');
  $('.rw-header__top .rw-nav__dropdown-container').addClass('hidden');
  $('button.rw-nav__trigger--open').removeClass('hidden');
  $('button.rw-nav__trigger--close').addClass('hidden');
  $('#nav-search--wide .rw-nav__dropdown-container').addClass('hidden');
});

// $('#nav-main .rw-nav__dropdown-item').on('click',function(e){
  // e.stopPropagation();
   // $('.rw-nav__dropdown-container').addClass('hidden');
// });

$('#nav-search--wide button.rw-nav__list-link').on('click',function(e){
  e.stopPropagation();
  $('#nav-main .rw-nav__dropdown-container').addClass('hidden');
  $('#nav-search--wide .rw-nav__dropdown-container').removeClass('hidden');
  $('#nav-search--wide button.rw-nav__trigger--open').addClass('hidden');
  $('#nav-search--wide button.rw-nav__trigger--close').removeClass('hidden');
  
  $('#nav-main button.rw-nav__trigger--open').removeClass('hidden');
  $('#nav-main button.rw-nav__trigger--close').addClass('hidden');
});
$('#nav-search--wide button.rw-nav__trigger--close').on('click',function(e){
  e.stopPropagation();
  $('#nav-search--wide .rw-nav__dropdown-container').addClass('hidden');
  $('#nav-search--wide button.rw-nav__trigger--open').removeClass('hidden');
  $('#nav-search--wide button.rw-nav__trigger--close').addClass('hidden');
});

$('#nav-search--wide .rw-nav__dropdown-container').on('click',function(e){
  e.stopPropagation();
});

$('#nav-search--narrow').on('click',function(e){
  e.stopPropagation();
  $('#nav-main .rw-nav__dropdown-container').addClass('hidden');
  $('.rw-header__top .rw-nav__dropdown-container').addClass('hidden');
  $('#nav-search--narrow .rw-nav__dropdown-container').removeClass('hidden');
  $('#nav-search--narrow button.rw-nav__trigger--open').addClass('hidden');
  $('#nav-search--narrow button.rw-nav__trigger--close').removeClass('hidden');
});

$('#nav-search--narrow button.rw-nav__trigger--close').on('click',function(e){
  e.stopPropagation();
  $('#nav-search--narrow .rw-nav__dropdown-container').addClass('hidden');
  $('#nav-search--narrow button.rw-nav__trigger--open').removeClass('hidden');
  $('#nav-search--narrow button.rw-nav__trigger--close').addClass('hidden');
});


$('.rw-header__top nav.rw-nav button.rw-nav__list-link').on('click',function(e){
  e.stopPropagation();
  $('.rw-header__top .rw-nav__dropdown-container').removeClass('hidden');
  $('#nav-main .rw-nav__dropdown-container').addClass('hidden');
  $('.rw-header__top button.rw-nav__trigger--open').addClass('hidden');
  $('.rw-header__top button.rw-nav__trigger--close').removeClass('hidden');
  $('#nav-search--wide .rw-nav__dropdown-container').addClass('hidden');
  $('#nav-main button.rw-nav__trigger--open').removeClass('hidden');
  $('#nav-main button.rw-nav__trigger--close').addClass('hidden');
});

$('.rw-header__top button.rw-nav__trigger--close').on('click',function(e){
  e.stopPropagation();
  $('.rw-header__top  .rw-nav__dropdown-container').addClass('hidden');
  $('.rw-header__top  button.rw-nav__trigger--open').removeClass('hidden');
  $('.rw-header__top  button.rw-nav__trigger--close').addClass('hidden');
});

$('#nav-main .rw-nav__list-item').on('click',function(e){
  e.stopPropagation();
  $('button.rw-nav__trigger--open').removeClass('hidden');
  $('button.rw-nav__trigger--close').addClass('hidden');

  $('button.rw-nav__trigger--open', this).toggleClass('hidden');
  $('button.rw-nav__trigger--close', this).toggleClass('hidden');

    $('.rw-nav__dropdown-container').addClass('hidden');
    $('.rw-nav__dropdown-container', this).toggleClass('hidden');
});

$('.category-list__item').on('click',function(e){
    e.stopPropagation();
    $('.category-list__item--active').removeClass('category-list__item--active');
    $(this).addClass('category-list__item--active');
    var chapter = $("a", this).attr("data-id");
    $('.subchapter-tiles').addClass('hidden');
    $('[id = ' + chapter + ']').removeClass('hidden');
    $('.category-list__list').toggleClass('hidden');
});  
  
$('.category-list__title').on('click',function(e){
    e.stopPropagation();
  $('.category-list__list').toggleClass('hidden');
  $('.visibility-switch__button').toggleClass('is-open');
});

$('.rw-footer-header').on('click',function(e){
   //$('.rw-footer-links').toggleClass('desktop');
   $(this).next('ul').toggleClass('desktop');
   $('button', this).toggleClass('is-open');
});

$('.rw-nav__dropdown-item').on('click',function(e){
  e.stopPropagation();
  // $('> ul', this).toggleClass('hidden');
  $(this).next('ul').toggleClass('hidden');
  $(this).find('svg').toggleClass('hidden');
});

$('#mobilemenu li a').on('click',function(e){
  e.stopPropagation();
  // $('> ul', this).toggleClass('hidden');
  $(this).next('ul').toggleClass('hidden');
  $(this).parent().children('svg').toggleClass('hidden');
});